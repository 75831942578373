import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import PropTypes from "prop-types";
import { CreditCardVaultWidget } from "./credit-card-vault-widget";

import { API, graphqlOperation } from "aws-amplify";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../components/CompanySelector.jsx";

const CreditCardVaultModal = (props) => {
  const [modal, setModal] = useState(false);
  const [payUrl, setPayUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const toggle = () => {
    const newStatus = !modal;
    if (!newStatus) {
      setPayUrl("");
      setLoading(true);
    }
    setModal(newStatus);
    props.closeModal(newStatus);
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (modal) {
      API.post("dashboardapi", "/v1/secure/card", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
        body: { quoteId: props.quote.id },
      }).then((response) => {
        setPayUrl(response.url);
        setLoading(false);
      });
    }
  }, [modal]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} className={"modal-dialog"}>
        <ModalHeader
          toggle={toggle}
          style={{ fontWeight: "bolder !important" }}
        >
          Credit Card Vault
        </ModalHeader>
        <ModalBody className={"text-center"}>
          {loading ? (
            <Spinner>
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              <iframe
                title={"pay-url"}
                src={payUrl}
                style={{
                  width: "100%",
                  height: 145,
                  border: "none",
                  overflow: "hidden",
                }}
              ></iframe>
              <span>
                Card Expiration:{" "}
                <strong>
                  {props.quote.paymentInfo.expireMonth} /{" "}
                  {props.quote.paymentInfo.expireYear}
                </strong>
              </span>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Got It!
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

CreditCardVaultWidget.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CreditCardVaultModal;
