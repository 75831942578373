import React, { useState, useEffect, useCallback, useContext } from "react";
import { API } from "aws-amplify";
import NewBotStepButton from "./NewBotStepButton.jsx";
import { Button, Accordion, Card, Spinner } from "react-bootstrap";
import SortableTable from "../sortable-table/SortableTable";
import StyleContext from "../../styling/styleContext";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const BotQuestionsDisplay = ({ type, openVersionControl }) => {
  const style = useContext(StyleContext);

  const [botId, setBotid] = useState(0);
  const [companyId, setCompanyId] = useState(0);

  const [botQuestions, setBotQuestions] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [image, setImages] = useState();

  const [refetch, setRefetch] = useState();
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const fetchBotQuestions = useCallback(async () => {
    toggleLoading(true);
    const company = await API.get("dashboardapi", "/v1/company", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId,
      },
    });
    const bot = company.bots.items[0];
    setBotid(bot.id);
    setCompanyId(bot.botCompanyId);
    setBotQuestions(JSON.parse(bot[type]));
    toggleLoading(false);
  }, [type, activeCompanySnapshot?.companyId]);

  useEffect(() => {
    fetchBotQuestions();
  }, [fetchBotQuestions]);
  const displayQuestions = [];

  if (botQuestions.length) {
    botQuestions[0].steps
      .concat(botQuestions[0].probing_questions)
      .forEach((step, index) => {
        displayQuestions.push({
          ...step,
        });
      });
  }

  const typeSeperated = type.replace(/([a-z])([A-Z])/, "$1 $2");

  return (
    <div className="container">
      <NewBotStepButton
        updateBotQuestions={setBotQuestions}
        type={type}
        botId={botId}
        openVersionControl={openVersionControl}
      />
      <div>
        <h2
          style={{
            ...style.styleSizing,
            color: style.colors.blue,
            fontSize: 24,
            marginBottom: 25,
            fontWeight: 300,
          }}
        >
          {`Current ${
            typeSeperated.charAt(0).toUpperCase() + "" + typeSeperated.slice(1)
          } Steps:`}{" "}
        </h2>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: 70 }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          // <SortableTable allSteps={displayQuestions} data={displayQuestions} updateBotQuestions={setBotQuestions} type={type} botId={botId} />
          <SortableTable
            allSteps={displayQuestions}
            data={displayQuestions}
            updateBotQuestions={setBotQuestions}
            type={type}
            botId={botId}
            refetch={refetch}
            setRefetch={setRefetch}
          />
        )}
      </div>
    </div>
  );
};

export default BotQuestionsDisplay;

// step.displayName || step.id
// + ` trigger: ${step.trigger}`
// <Card>
//   <Card.Header style={{ display: "flex", alignItems: "center" }}>
//     <span>{step.displayName || step.id}</span>
//     <Accordion.Toggle
//       as={Button}
//       style={{ position: "absolute", right: 0 }}
//       variant="link"
//       eventKey={`step-${index}`}
//     >
//       <i class="fas fa-caret-down"></i>
//     </Accordion.Toggle>
//   </Card.Header>
//   <Accordion.Collapse eventKey={`step-${index}`}>
//     <Card.Body>{step.displayName || step.id}</Card.Body>
//   </Accordion.Collapse>
// </Card>
