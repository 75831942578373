import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as phone_formatter from "phone-formatter";
import { Button } from "reactstrap";
import { currencies } from "../currencies/currencies";
import { calculateProgramChargeAmount } from "./../utils/pricing";
import { formatV1Quote } from "../utils/quote.format";
import _, { sum } from "lodash";
const {
  priceCalculations,
  installments,
  offers,
} = require("@workwave_it/lawnbot-pricing-calculations");

export const CustomerBlockWidget = (props) => {
  const quote = props.quote;
  const customer = quote.address.customer;
  const address = quote.address;

  const colors = quote.convertedSale
    ? { color1: "#E2FFD9", color2: "#D7F4CE" }
    : { color1: "#FDEFD9", color2: "#F7D4A5" };
  const gradient = quote.convertedSale ? "68%" : "73%";

  let saleAmount;
  let charge;

  const [summary, setSummary] = useState({
    price: 0.0,
    tax: 0.0,
  });

  const [programs, setPrograms] = useState([]);

  const [terms, setTerms] = useState("applications");
  const [labels, setLabels] = useState({ switchLabel: "", emailLabel: "" });
  let currencySymbol = "";
  if (props.company.currency) {
    currencies.forEach((curr) => {
      if (curr.name === props.company.currency) {
        currencySymbol = curr.symbol;
      }
    });
  } else {
    currencySymbol = "$";
  }
  useEffect(() => {
    setTerms(quote.paymentTermSelected);

    if (!quote.selectedPrograms) quote.selectedPrograms = `[]`;
    if (!quote.recommendedProgams) quote.recommendedProgams = `[]`;
    try {
      quote.recommendedProgams = JSON.parse(quote.recommendedProgams);
    } catch (e) {}

    try {
      quote.selectedPrograms = JSON.parse(quote.selectedPrograms);
    } catch (e) {}

    quote.selectedPrograms = quote.selectedPrograms.map((p) => {
      const newP = quote.recommendedProgams.find(
        (f) => f.serviceName === p.programName,
      );
      if (newP) {
        newP.programName = newP.serviceName;
      }

      if (!p.services) {
        if (newP) {
          return newP;
        }
        return p;
      }
      if (
        (!p.offers && newP && newP.offers) ||
        (p.offers &&
          !p.offers.length &&
          newP &&
          newP.offers &&
          newP.offers.length)
      ) {
        p.offers = newP.offers;
      } else if (!p.offers) {
        p.offers = [];
      }
      p.serviceName = p.programName;
      if (newP) {
        p.id = newP.id;
      }
      return p;
    });

    const details = quote.convertedSale
      ? quote.selectedPrograms
      : quote.recommendedProgams;

    setPrograms(details);
    if (details.length) {
      const labels = installments.getInstallmentsLabels(
        props.company.autoPayPriceMethod,
        props.company.autoPayMonthlyPricePeriod || 12,
        details[0].applicationLabel,
        quote.paymentTermSelected === "total"
          ? "Pre-Pay"
          : props.company.autoPayAlternativeLabel,
      );
      setLabels(labels);
    }
  }, [props, quote]);

  useEffect(() => {
    if (props.company && programs.length) {
      if (programs[0].services && programs[0].services.application) {
        let s = { price: "NA", tax: 0.0 };
        try {
          s = priceCalculations.getSummaryPricing(programs, terms, {
            autoPayPriceMethod: props.company.autoPayPriceMethod,
            autoPayMonthlyPricePeriod:
              props.company.autoPayMonthlyPricePeriod || 12,
            serviceTax: props.company.serviceTax,
            lookupZipCodeTax: props.company.lookupZipCodeTax,
            taxOverride: props.company.taxOverride,
            currency: props.company.currency,
          });
        } catch (e) {}
        setSummary(s);
      } else {
        const parts = programs.map((p) => {
          const method = formatV1Quote;
          const z = method(p);
          const pCost = z["pCost"];
          return pCost;
        });

        let total = _.reduce(
          parts,
          (sum, ii) => {
            sum += ii;
            return sum;
          },
          0.0,
        );

        if (!isNaN(total)) {
          total = Number.parseFloat(total.toFixed(2));
        }
        const ss = {
          price: total,
          tax: 0,
        };

        setSummary(ss);
      }
    }
  }, [programs, props.company, terms]);

  const viewCard =
    quote.convertedSale &&
    quote.paymentInfo.paymentType === "lawnbot-credit-card-vault" ? (
      <Button
        style={{
          backgroundColor: "#B43434",
          color: "white",
          fontWeight: "bold",
          borderRadius: 10,
          marginRight: 20,
          position: "relative",
          float: "right",
        }}
        onClick={props.openCb}
      >
        Credit Card
      </Button>
    ) : (
      ""
    );
  const taxOverride =
    props.company.autoPayPriceMethod === "monthly-payment" &&
    quote.paymentTermSelected !== "total";
  // a check needed for some quotes that hit a bug with the taxes
  if (typeof summary.tax !== "string" && isNaN(summary.tax)) {
    summary.tax = 0;
  }
  return (
    <>
      <div
        className="card card-block"
        style={{
          paddingBottom: 15,
          borderRadius: 15,
          background: `linear-gradient(-180deg, ${colors.color1} ${gradient}, ${colors.color2} 20%)`,
          borderColor: "#707070",
          boxShadow: "2px 2px 2px #C6B8B8",
        }}
      >
        <div className="row">
          <div className="col-md-8">
            <h4 className="card-title">{customer.name}</h4>
          </div>
          <div className="col-md-4">
            {props.isRealGreen ? (
              <Button
                style={{
                  backgroundColor: "white",
                  color: "black",
                  position: "relative",
                  float: "right",
                  borderRadius: 10,
                  fontWeight: "bolder",
                  fontFamily: "'Proxima Nova'",
                }}
              >
                Open in &nbsp;{" "}
                <img
                  src={"/assets/rg-small.png"}
                  style={{ width: 20 }}
                  alt=""
                />
              </Button>
            ) : null}
            {viewCard}
          </div>
        </div>

        <div className="row" style={{ paddingBottom: 20 }}>
          <div className="col-md-9">
            <span style={{ fontWeight: "bold" }}>{address.address}</span>
            <br />
            <span style={{ fontWeight: "bold" }}>
              {address.city}, {address.state} {address.postalCode}
            </span>
            <p className="card-text" style={{ fontWeight: "bold" }}>
              {customer.phoneNumber ? (
                <>
                  P -{" "}
                  <a
                    style={{ textDecoration: "underline", color: "#007bff" }}
                    href={
                      "tel:" +
                      phone_formatter.format(
                        customer.phoneNumber,
                        "NNN-NNN-NNNN",
                      )
                    }
                  >
                    {phone_formatter.format(
                      customer.phoneNumber,
                      "NNN-NNN-NNNN",
                    )}
                  </a>{" "}
                  &nbsp; <span className="fa fa-phone"></span>&nbsp;&nbsp;
                </>
              ) : null}
              {customer.email ? (
                <>
                  {" "}
                  E -{" "}
                  <a
                    style={{ textDecoration: "underline", color: "#007bff" }}
                    href={"mailto:" + customer.email}
                  >
                    {customer.email}
                  </a>{" "}
                  &nbsp; <span className="fa fa-envelope"></span>
                </>
              ) : null}
            </p>
          </div>
          <div className="col-md-3">
            <div
              className="text-muted"
              style={{
                textAlign: "right",
                bottom: 0,
                paddingTop: 10,
                fontSize: 13,
              }}
            >
              {quote.convertedSale ? <strong>SALE MADE</strong> : "QUOTED"}
              <br />
              {new Date(quote.createdAt).toLocaleString()}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <div
              style={{
                textAlign: "center",
                paddingRight: 0,
                fontSize: 12,
                marginTop: 10,
                fontWeight: "bolder",
              }}
            >
              {quote.convertedSale ? (
                <>
                  <strong>Sale Type: </strong>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {labels.emailLabel}
                  </span>
                  <br />
                  {quote.convertedSale ? (
                    <>
                      <strong>Amount Authorized: </strong>
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {`${
                          taxOverride
                            ? `${currencySymbol}${summary.price} ${summary.tax}`
                            : `${currencySymbol}${summary.price + summary.tax}`
                        }`}
                      </span>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>

        {/* <a href="#" className="btn btn-primary">View Customer Details</a> */}
      </div>
    </>
  );
};

CustomerBlockWidget.propTypes = {
  quote: PropTypes.object.isRequired,
  openCb: PropTypes.func.isRequired,
  isRealGreen: PropTypes.bool,
};
