import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Spinner,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!"),
  );

  return (
    <Button
      type="button"
      style={{ backgroundColor: "#009879" }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function AccordionComp({ accordions, id }) {
  return (
    <Accordion>
      <Card
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card.Header>
          <CustomToggle eventKey="0">{id}</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Row
            style={{
              width: "60%",
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            {accordions.map((label, index) => {
              return (
                <Col key={`Other_Key_${index}`} xs="12" lg="6" md="6">
                  <Card style={{ width: "auto", height: "150px" }}>
                    <Card.Header
                      style={{ backgroundColor: "#009879", color: "white" }}
                    >{`Option ${index + 1}`}</Card.Header>
                    <Card.Body>{label.label}</Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

const OtherDraggable = ({
  step,
  provided,
  index,
  updateBotQuestions,
  type,
  botId,
}) => {
  const [other, setOther] = useState();

  useEffect(() => {
    setOther(step);
  }, [other]);

  // return !other ? null : (<div style={{ width: 200, height: 150 }} data-testid='has-options'>
  //     <Draggable

  //         key={`draggable_key_${index}`}
  //         draggableId={`${index + 0}`}
  //         index={index}
  //     >
  //         {(provided) => (

  //             <div>{other.map((question) => {
  //                 console.log('this is   uestions', question)
  //                 if (question.probing_questions && question.probing_questions.length > 0) {
  //                     return (
  //                         <div>{other.map((a, i) => {
  //                             console.log('this is aaa', a)
  //                             return (
  //                                 <>
  //                                     <h4>Probing Questions</h4>
  //                                     <div>{a.probing_questions.map((b) => (
  //                                         <div>
  //                                             <AccordionComp id={b.id} accordions={b.options} />
  //                                         </div>

  //                                         // <Card.Body>
  //                                         //     <AccordionComp accordions={b.options} />
  //                                         // </Card.Body>
  //                                         // <Card>
  //                                         //     <Card.Title>{b.id}</Card.Title>
  //                                         //     <Card.Text>{b.optionType}</Card.Text>
  //                                         //     <Card.Text>{b.setVariable}</Card.Text>
  //                                         // </Card>
  //                                     ))}</div>
  //                                 </>
  //                             )

  //                         })}</div>

  //                     )
  //                 }
  //             })
  //             }</div>

  //         )}
  //     </Draggable>

  // </div >);

  return !other ? null : (
    <div data-testid="has-options">
      {other.map((question) => {
        if (
          question.probing_questions &&
          question.probing_questions.length > 0
        ) {
          return (
            <div>
              {other.map((a, i) => {
                return (
                  <>
                    <h4>Probing Questions</h4>
                    <div key={`Probing_Questions_Key_${i}`}>
                      {a.probing_questions.map((b) => (
                        <div key={`Probing_Questions_${i}`}>
                          <AccordionComp
                            // key={`Probing_Questions_${i}`}
                            // id={b.id}
                            accordions={b.options}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                );
              })}
            </div>
          );
        }
      })}
    </div>
  );
};

export default OtherDraggable;
