import React from "react";
import PropTypes from "prop-types";
import * as phone_formatter from "phone-formatter";

import * as _ from "lodash";
import { Link } from "react-router-dom";
import { reverse } from "lodash";
export const QuoteTable = (props) => {
  const sorted = _.sortBy(props.rows, (o) => new Date(o.createdAt));
  reverse(sorted);

  return props.rows.length ? (
    <div className="table-responsive">
      <table className="table table-bordered table-striped m-b-0">
        <thead>
          <tr className="align-middle text-muted">
            <th></th>
            <th>Customer</th>
            <th>Address</th>

            <th>Status</th>

            <th></th>
          </tr>
        </thead>

        <tbody>
          {sorted.map((i) => {
            let image = null;
            if (i.estimate) {
              if (i.estimate.measurItImage) {
                image = i.estimate.measurItImage;
              }
            }

            let serviceTypes = [];
            try {
              if (i.serviceTypes) {
                serviceTypes = JSON.parse(i.serviceTypes);
              }
            } catch (e) {}
            const key = "rowkey_" + Math.floor(Math.random() * 1000000);

            return (
              <>
                <tr key={key} className="align-middle" id={`quote_${i.id}`}>
                  <td>
                    {image ? (
                      <img
                        style={{ width: 100 }}
                        key={key + "-image"}
                        src={image}
                        alt=""
                      />
                    ) : null}
                  </td>
                  <td>
                    {i.customer ? i.customer.name : "Unknown"}
                    <br />
                    {i.customer && i.customer.phoneNumber
                      ? phone_formatter.format(
                          i.customer.phoneNumber,
                          "(NNN) NNN-NNNN",
                        )
                      : ""}
                    <br />
                    {i.customer ? i.customer.email : ""}
                  </td>
                  <td style={{ paddingTop: "3%" }}>
                    <a
                      onClick={() => {
                        if (props.showMarker) {
                          props.showMarker(i.addressId);
                        }
                      }}
                    >
                      <span
                        style={{ textDecoration: "underline dotted" }}
                        className="small-address"
                      >
                        {i.address}
                        <br />
                        {i.city ? i.city : ""} {i.state ? i.state : ""}{" "}
                        {i.postalCode}
                      </span>
                    </a>
                  </td>

                  <td style={{ paddingTop: "4%" }}>
                    <span
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        borderRadius: 10,
                        backgroundColor: i.convertedSale
                          ? "#22D4AE"
                          : "#FCDC69",
                      }}
                      className={`label full-font-size`}
                    >
                      {i.convertedSale ? "Sale" : "Lead"}
                    </span>
                  </td>

                  <td style={{ textAlign: "center", paddingTop: "3%" }}>
                    <Link to={`/quote/${i.id}/details`} target="_blank">
                      <button
                        style={{ backgroundColor: "#4E7CFF" }}
                        id={key}
                        type="button"
                        className="btn btn-primary btn-sm btn-icon loading-demo mr5"
                      >
                        <i className="icon-eye mr5"></i> <span>Details</span>
                      </button>
                    </Link>
                    <br />
                    <span
                      style={{
                        fontStyle: "italic",
                        marginTop: 3,
                        fontSize: 11,
                      }}
                    >
                      {new Date(i.createdAt).toLocaleDateString()}
                    </span>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <h2>No Quotes Available</h2>
  );
};

QuoteTable.propTypes = {
  showMarker: PropTypes.func,
};
