import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import $ from "jquery";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import AdminRoute from "./utils/AdminRoute.jsx";

import { Auth, API } from "aws-amplify";

import { SideBarPanel } from "./layout/sidebar-panel";
import { TopHeader } from "./layout/top-header";
import { DashboardScreen } from "./screens/dashboard-screen";
import { Footer } from "./layout/footer";
import { QuotesListScreen } from "./screens/quotes-list-screen";
import { QuoteDetailScreen } from "./screens/quote-detail-screen";
import { SettingsScreen } from "./screens/settings.screen";
import "react-image-lightbox/style.css";
import { MeasurItIframeScreen } from "./screens/measurit-iframe-screen";
import ForgotPass from "./components/auth/forgotPass";
import CustomSignIn from "./components/auth/signIn";
import { SetupScreen } from "./selfserve/setup/setup.screen";

import "react-toastify/dist/ReactToastify.css";

/* Preloader */
function preloader() {
  if ($("body > .pageload").length) {
    if ($("body").hasClass("page-loaded")) {
      return;
    }
    $("body").addClass("page-loaded").removeClass("page-loading");
    $("body > .pageload").fadeOut();
  }
}

function App() {
  const [branches, setBranches] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const fn = async () => {
    const info = await API.get("dashboardapi", "/v1/dashboard/info", {});
    setIsAdmin(info.isAdmin);
    setBranches(info.branches);
  };
  const onLoad = useCallback(async () => {
    try {
      await Auth.currentSession();
      await fn();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        //onError(e);
      } else {
        // window.location = "/";
        userHasAuthenticated(false);
        const loc = window.location.pathname;
        // TODO: create private routes
        if (["/", "/reset", ""].includes(loc)) {
        } else {
          window.location = "/";
        }
      }
    }
    preloader();
  }, []);
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div>
      <Router>
        {
          <div>
            {!isAuthenticated ? (
              <>
                <Route exact path="/">
                  <CustomSignIn />
                </Route>

                <Route exact path="/reset">
                  <ForgotPass />
                </Route>
              </>
            ) : (
              <>
                <SideBarPanel isAdmin={isAdmin} branches={branches} />
                <div className="main-panel">
                  <TopHeader branches={branches} />
                  <div className="main-content">
                    <Switch>
                      <Route exact path="/">
                        {/*<button onClick={handleLogout}>SIGN OUT</button>*/}
                        <DashboardScreen />
                      </Route>
                      <Route path="/quote/:id/details">
                        <QuoteDetailScreen branches={branches} />
                      </Route>
                      {/* <Route path="/quotes/customer/:filterCustomerId">
                <QuotesListScreen />
              </Route> */}
                      <Route path="/quotes/address/:filterAddressId">
                        <QuotesListScreen />
                      </Route>
                      <Route path="/quotes/:filterType">
                        <QuotesListScreen />
                      </Route>
                      <Route path="/quotes">
                        <QuotesListScreen />
                      </Route>
                      <Route path="/measurit">
                        <MeasurItIframeScreen />
                      </Route>

                      {/* <Route path="/customers">
                <CustomerListScreen />
              </Route> */}

                      <Route path="/settings">
                        <SettingsScreen isAdmin={isAdmin} />
                      </Route>

                      <AdminRoute
                        isAdmin={isAdmin}
                        path="/setup"
                        component={SetupScreen}
                        componentProps={{ branches: branches }}
                      />
                    </Switch>
                  </div>
                  <Footer />
                </div>
              </>
            )}
          </div>
          // )
        }
      </Router>
    </div>
  );
}

export default App;
