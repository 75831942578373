import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import useResetUserPassword from "../../hooks/users/useResetUserPassword";
import { ToastContainer } from "react-toastify";

const ResetPassword = ({ users }) => {
  const [userToResetPassword, setUserToResetPassword] = useState(
    users[0]?.email,
  );
  const { resetUserPassword, loading } = useResetUserPassword({
    email: userToResetPassword,
  });

  const handleResetPassword = async () => {
    await resetUserPassword(userToResetPassword);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card bg-white card-block">
          <h5>Reset Password</h5>

          <select
            style={{ width: 300 }}
            onChange={(e) => setUserToResetPassword(e.target.value)}
            value={userToResetPassword}
          >
            {users.map((user) => (
              <option key={user.email} value={user.email}>
                {user.email}
              </option>
            ))}
          </select>
          <br />

          <Button
            onClick={handleResetPassword}
            style={{ width: 150 }}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Reset Password"
            )}
          </Button>

          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
