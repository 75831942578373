import { Auth } from "aws-amplify";

import { useState } from "react";
import { toast } from "react-toastify";

const useResetUserPassword = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const resetUserPassword = async (email) => {
    setLoading(true);
    setData(null);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_API}/v1/management/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ email }),
        },
      );
      const responseData = await response.json();
      if (response.status === 200) {
        await Auth.forgotPassword(email);
        toast.success("Password reset successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(responseData.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      setData(responseData);
    } catch (err) {
      toast.error(
        err.message || "An error occurred while resetting the password",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    loading,
    resetUserPassword,
  };
};

export default useResetUserPassword;
