import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  FormFeedback,
  Col,
} from "reactstrap";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./pricing-form.css";
import StyleContext from "../../styling/styleContext";
import API from "@aws-amplify/api";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

function checkZips(value, rgZipCodes, setZipError) {
  if (value && value.length && rgZipCodes && rgZipCodes.length) {
    let valueArray = value.split(/[\n,\s]/g).map((i) => i.trim());
    const rgArray = rgZipCodes.split(/[\n,\s]/g).map((i) => i.trim());
    valueArray = valueArray.filter((zip) => zip);
    const notAllowedArray = valueArray.filter((x) => !rgArray.includes(x));

    if (notAllowedArray.length) {
      let errorString = "";
      notAllowedArray.forEach((zip, index) => {
        errorString += zip;

        if (index === notAllowedArray.length - 1) {
          errorString += ".";
        } else {
          errorString += ", ";
        }
      });
      errorString +=
        " These zip codes are not in your SA5 and are should not be added to your bot zips.";
      setZipError(errorString);
    } else {
      setZipError("");
    }
  }
}

const PricingForm = ({ renderHelpButtons, openVersionControl }) => {
  const style = useContext(StyleContext);

  const notify = (val) => toast(val);

  const [active, setActive] = useState(false);
  const [priceMethod, setPriceMethod] = useState("");
  const [pricePeriod, setPricePeriod] = useState("");
  const [companys, setCompanys] = useState("");
  const [crm, setCrm] = useState("real-green");
  const [botid, setBotid] = useState(0);
  const [zipCodes, setZipCodes] = useState("");
  const [rgZips, setRGZips] = useState("");
  const [loading, setLoading] = useState(true);
  const [dontTaxZipCodes, setDontTaxZipCodes] = useState("[]");
  const [zipError, setZipError] = useState("");
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const updateZipCodes = (value) => {
    if (value && crm === "real-green") {
      checkZips(value, rgZips, setZipError);
    }
    setZipCodes(value);
  };
  const [state, setState] = useState({
    bypassPayment: false,
    hideSummary: false,
    autoPayAvailable: false,
    prePayAvailable: false,
    lookupZipCodeTax: false,
  });

  const toggler = (val) => {
    val = typeof val !== "string" ? val.target.name : val;
    setActive(!active);
    setState(state, (state[val] = !state[val]));
  };

  const submitData = async (data) => {
    await API.post("dashboardapi", "/v1/bot", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId,
      },
      body: data,
    });
  };

  useEffect(() => {
    async function fetchCompanys() {
      setLoading(true);
      const response = await API.get("dashboardapi", "/v1/companys", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });
      const data = await response;
      const {
        bots,
        dataServices,
        autoPayAvailable,
        bypassPayment,
        prePayAvailable,
        autoPayPriceMethod,
        autoPayMonthlyPricePeriod,
        crmSolution,
      } = data.message.data.getCompanyVersioned;
      if (crmSolution === "real-green" && dataServices.realGreenAPIKey) {
        const availableZips = await API.post(
          "dashboardapi",
          "/v1/real-green-zips",
          {
            headers: {
              "Company-Id": activeCompanySnapshot?.companyId,
            },
            body: {
              apiKey: dataServices.realGreenAPIKey,
              companyId:
                activeCompanySnapshot?.companyId ??
                data.message.data.getCompanyVersioned.id,
            },
          },
        );
        if (!availableZips.error) {
          let zips = "";
          availableZips.message.forEach(
            (code) => (zips += code.zip.toString() + "\n"),
          );
          setRGZips(zips);
        }
      }
      setZipCodes(bots.items.map((a) => a.zipCodes)[0]);
      const lookupZipCodeTax = bots.items[0].lookupZipCodeTax;
      setCompanys(data.message.data);
      setCrm(crmSolution);
      setBotid(bots.items.map((a) => a.id)[0]);
      setDontTaxZipCodes(bots.items.map((a) => a.dontTaxZipCodes)[0]);
      setState((prev) => {
        return {
          ...prev,
          autoPayAvailable,
          bypassPayment,
          prePayAvailable,
          lookupZipCodeTax,
        };
      });
      setPriceMethod(autoPayPriceMethod);
      setPricePeriod(autoPayMonthlyPricePeriod || 12);
      setLoading(false);
    }
    fetchCompanys();
    renderHelpButtons("pricing");
  }, [renderHelpButtons, activeCompanySnapshot?.companyId]);

  const headerStyling = {
    ...style.styleSizing,
    fontWeight: 500,
    color: style.colors.blue,
    marginBottom: 20,
    fontSize: 20,
    paddingLeft: 5,
  };

  return loading ? (
    <div id="crm-spinner">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <div
      style={{
        maxWidth: "1440px",
        background: "whitesmoke",
        borderRadius: "25px",
        padding: "25px",
        marginTop: 20,
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <h3
            id="real-green-header"
            style={{ ...style.styleSizing, fontSize: 26, marginBottom: 20 }}
          >
            Company Pricing
          </h3>
          <Button
            disabled={!botid}
            style={{
              ...style.styleSizing,
              ...style.submitButton(style.colors.green),
              marginBottom: 20,
            }}
            onClick={() => {
              openVersionControl("company", companys.id, null, {
                crmSolution: true,
                paymentType: true,
                bypassPayment: true,
                autoPayAvailable: true,
                prePayAvailable: true,
                autoPayPriceMethod: true,
              });
            }}
          >
            Version Control
          </Button>
        </div>
        <Row>
          <Col xs="12" lg="6" md="6">
            <FormGroup>
              <Label
                className="context real-green-header"
                id="real-green-header"
                style={{ ...style.styleSizing, color: style.colors.blue }}
                for="crmSolution"
              >
                CRM Solution Select:
              </Label>
              <Input
                style={style.dropDown}
                onChange={(e) => setCrm(e.target.value)}
                value={crm}
                type="select"
                name="select"
                className="pricing-select"
              >
                <option>{"none"}</option>
                <option value="real-green">{"real-green"}</option>
                <option value="pest-pac">{"pest-pac"}</option>
                <option value="servman">{"servman"}</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs="12" lg="6" md="6">
            <FormGroup>
              <Label
                className="context real-green-header"
                id="real-green-header"
                style={{ ...style.styleSizing, color: style.colors.blue }}
                for="creditCardProcessing"
              >
                Credit Card Processing Select:
              </Label>

              <Input
                style={style.dropDown}
                type="select"
                name="select"
                className="pricing-select"
              >
                {crm === "real-green" ? (
                  <>
                    <option>Real Green Payment Processing</option>
                    <option>Lawnbot Credit Card Vault</option>
                  </>
                ) : (
                  <option>Lawnbot Credit Card Vault</option>
                )}
              </Input>
            </FormGroup>
            {/* </div>
             */}
          </Col>
        </Row>

        <FormGroup check>
          <Label
            id="bypass-payment"
            className="context real-green-header"
            style={{ ...style.styleSizing, color: style.colors.green }}
            check
          >
            <Input
              checked={state.bypassPayment}
              onClick={() => toggler("bypassPayment")}
              type="checkbox"
            />{" "}
            Bypass Payment
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label
            id="hide-pricing-summary"
            className="context real-green-header"
            style={{ ...style.styleSizing, color: style.colors.green }}
            check
          >
            <Input type="checkbox" /> Hide Pricing Summary
          </Label>
        </FormGroup>
        <FormGroup check>
          <Label
            className="context real-green-header"
            id="lookup-tax-from-zip-code"
            style={{ ...style.styleSizing, color: style.colors.green }}
            check
          >
            <Input
              checked={state.lookupZipCodeTax}
              onClick={() => toggler("lookupZipCodeTax")}
              type="checkbox"
            />{" "}
            Look Up Tax from ZipCode
          </Label>
        </FormGroup>

        <FormGroup check>
          <Label
            id="auto-pay-available"
            className="context real-green-header"
            style={{ ...style.styleSizing, color: style.colors.green }}
            check
          >
            <Input
              checked={state.autoPayAvailable}
              onClick={() => toggler("autoPayAvailable")}
              type="checkbox"
            />{" "}
            Auto-Pay Available
          </Label>
        </FormGroup>

        <FormGroup check>
          <Label
            id="pre-pay-available"
            className="context real-green-header"
            style={{ ...style.styleSizing, color: style.colors.green }}
            check
          >
            <Input
              checked={state.prePayAvailable}
              onClick={() => toggler("prePayAvailable")}
              type="checkbox"
            />{" "}
            Pre-Pay Available
          </Label>
        </FormGroup>

        <FormGroup>
          <Label
            className="context real-green-header"
            id="auto-pay-price-method"
            style={{
              ...style.styleSizing,
              marginTop: 20,
              color: style.colors.blue,
            }}
            for="autoPayPriceMethod"
          >
            Auto-Pay Price Method:{" "}
          </Label>
          <Input
            style={style.dropDown}
            onChange={(e) => setPriceMethod(e.target.value)}
            type="select"
            name="select"
            className="exampleSelect"
            value={priceMethod}
          >
            <option>first-application-payment</option>
            <option>monthly-payment</option>
          </Input>
        </FormGroup>

        {priceMethod === "monthly-payment" ? (
          <FormGroup>
            <Label
              id="real-green-header"
              style={{ ...style.styleSizing, color: style.colors.blue }}
              for="autoPayPricePeriod"
            >
              Auto-Pay Price Period:{" "}
            </Label>
            <Input
              style={style.dropDown}
              onChange={(e) => setPricePeriod(e.target.value)}
              type="select"
              name="select"
              className="exampleSelect"
              value={pricePeriod}
            >
              <>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
                  return (
                    <option key={`option_dropdown_key_${i}`} value={i}>
                      {i} Months
                    </option>
                  );
                })}
              </>
            </Input>
          </FormGroup>
        ) : null}
        <FormGroup>
          <h3
            className="context real-green-header"
            id="zip-codes"
            style={{
              ...style.styleSizing,
              fontSize: 20,
              marginTop: 40,
              marginBottom: 20,
              color: style.colors.blue,
            }}
          >
            Serviced Zip Codes
          </h3>
          <Input
            style={style.textArea}
            onChange={(e) => updateZipCodes(e.target.value)}
            value={zipCodes}
            type="textarea"
            rows="10"
            cols="10"
            name="text"
            id="exampleText"
            invalid={zipError}
          />
          <FormFeedback invalid>{zipError}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <h3
            className="context real-green-header"
            id="dont-tax-zip-code"
            style={{
              ...style.styleSizing,
              fontSize: 20,
              marginTop: 40,
              marginBottom: 20,
              color: style.colors.blue,
            }}
          >
            Do Not Tax These Zip Codes
          </h3>
          <Input
            style={style.textArea}
            onChange={(e) => setDontTaxZipCodes(e.target.value)}
            value={dontTaxZipCodes}
            type="textarea"
            rows="10"
            cols="10"
            name="text"
            id="exampleText"
          />
        </FormGroup>
        <Button
          style={style.submitButton(style.colors.green)}
          type="submit"
          onClick={() =>
            submitData({
              id: botid,
              prePayAvailable: state.prePayAvailable,
              autoPayAvailable: state.autoPayAvailable,
              lookupZipCodeTax: state.lookupZipCodeTax,
              dontTaxZipCodes: dontTaxZipCodes,
              bypassPayment: state.bypassPayment,
              crmSolution: crm,
              autoPayMonthlyPricePeriod:
                priceMethod === "monthly-payment" ? pricePeriod : null,
              autoPayPriceMethod: pricePeriod,
              zipCodes,
            })
          }
        >
          Submit
        </Button>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default PricingForm;
