import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MessageDraggable from "./Draggables/MessageDraggable";
import CreateNewBotStepModal from "../bot-step/CreateNewBotStepModal.jsx";
import { API } from "aws-amplify";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

import { onDragEnd } from "./Functions";
import "./DragAndDropContainer.css";
import { getNullableType } from "graphql";

const DragAndDropContainer = ({
  allSteps,
  handleOnDragEnd,
  type,
  botId,
  Images,
  refetch,
  updateBotQuestions,
}) => {
  console.log({ type });

  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const [setter, setSetter] = useState(false);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState({});

  console.log({ allSteps });

  const openModal = (step, index) => {
    setOpen(true);
    setStep(step);
  };

  const specialStepChange = (event, index, type) => {
    const { name, value } = event.target;
    const prevStep = step;
    if (name == "conditionals") {
      const conditionals = prevStep["conditionals"];

      if (type == "matches") {
        conditionals[index].matches = value;
      } else if (type == "trigger") {
        conditionals[index].trigger = value;
      }
    } else if (name == "options") {
      const { options, conditionals } = prevStep;
      options[index].value = value;
      conditionals ? ([index].matches = value) : null;
    }

    setStep(prevStep);
  };
  const handleStepChange = (event) => {
    const { name, value } = event.target;
    if (name === "message" && name.match(".")) {
      step[name] = value;
      console.log({ step });
      setStep({ ...step });
      return;
    }
    if (name.match(".")) {
      const [key, attr] = name.split("."); // [0, title]
      step[key][attr] = value;
      console.log({ step });
      setStep({ ...step });
    } else {
      setStep({
        ...step,
        [name]: value,
      });
    }
  };

  // const handleStepChange = (event) => {
  //   const { name, value } = event.target;

  //   console.log({ name, value });

  //   if (name.match(".")) {
  //     // const [key, attr] = name.split("."); // [0, title]
  //     // step[key][attr] = value;
  //     setStep({ ...step, [name]: value });
  //   } else {
  //     setStep({
  //       ...step,
  //       [name]: value
  //     });
  //   }
  // };

  console.log({ step });

  const addOption = async (option) => {
    step.options &&
      step.options.push({
        hideText: true,
        value: option.value,
        label: option.value,
      });
    step.conditionals &&
      step.conditionals.push({
        matches: option.value,
        trigger: option.trigger,
      });

    setStep({ ...step });
  };

  const saveStep = async ({ uploadedImages } = {}) => {
    setOpen(false);
    const company = await API.get("dashboardapi", "/v1/company", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId,
      },
    });
    const bot = company.bots.items[0];

    const botQuestions = JSON.parse(bot[type]);

    const replace = ({ id }, index, arr) => {
      if (id === step.id) {
        arr[index] = step;
      }
    };

    botQuestions.forEach(({ probing_questions, steps }, index) => {
      steps.forEach(replace);
      probing_questions.forEach(replace);

      probing_questions.forEach((questions) => {
        const { options } = questions;

        const imageStep = options && options.length && options[0].image;
        // sending new images across

        if (!imageStep) return;

        if (
          uploadedImages &&
          step.length > options.length &&
          options[index].value &&
          options[index].label
        ) {
          options.push({
            value: options[index].value,
            label: options[index].label,
            image: uploadedImages.pop(),
          });
        }

        options.forEach((option, index) => {
          if (!step[index]) return;

          // Update label
          const { title } = step[index];

          option.value = title;
          option.label = title;

          const newImage = uploadedImages[index];

          // Update image
          if (newImage) {
            option.image = newImage;
          }
        });
      });
    });

    const response = await API.put("dashboardapi", "/v1/update", {
      body: {
        id: botId,
        step: botQuestions,
        questionGroup: type,
      },
    });

    updateBotQuestions(botQuestions);
  };

  return (
    <>
      <CreateNewBotStepModal
        open={open}
        toggle={() => setOpen(!open)}
        step={step}
        setStep={setStep}
        saveStep={saveStep}
        extended={true}
        specialStepChange={specialStepChange}
        addOption={addOption}
        handleStepChange={handleStepChange}
        type={type}
        botId={botId}
        defaultOption={"simple-options"}
      />

      <header>
        <>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="ids">
              {(provided, snapShot) => (
                <ul
                  className="draggable-ids"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <div>
                    {allSteps.map((step, index) => {
                      if (!step.id) return;
                      return (
                        <div key={`Draggable_key`} className="">
                          <MessageDraggable
                            provided={provided}
                            index={index}
                            step={step}
                            openModal={() => openModal(step, index)}
                          />
                          {/* <ImageDraggable step={s} /> */}
                        </div>
                      );
                    })}
                  </div>
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <Images openModal={openModal} />
        </>
      </header>
    </>
  );
};

export default DragAndDropContainer;
