import React, { useState, useEffect } from "react";
import RenderPage from "../programs/render-page";
import VersionControl from "../version-control/VersionControl.jsx";
import { API } from "aws-amplify";
import {
  Route,
  BrowserRouter as Router,
  useRouteMatch,
} from "react-router-dom";

import "./setup.screen.css";
import BrandingInfo from "../branding/BrandingInfo.jsx";
import PricingTab from "../pricing-tab/pricing-tab";

import RealGreenCRM from "../real-green-crm/RealGreenCRM.jsx";
import { Switch } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import "./setup.screen.css";

import "./setup.screen.css";

import BotQuestionsDisplay from "../bot-step/BotQuestionsDisplay.jsx";
import { Nav, Tab, Spinner } from "react-bootstrap";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const realGreenTab = [
  // {
  //   tabId: "1",
  //   tabName: "Company Info",
  //   content: "Content company"
  // },

  {
    tabId: "2",
    tabName: "Pricing",
    content: "Content Pricing",
  },

  {
    tabId: "3",
    tabName: "Branding",
    content: "Content branding",
  },

  {
    tabId: "4",
    tabName: "Programs",
    content: "Programs Page",
  },
  {
    tabId: "5",
    tabName: "Real Green",
    content: "Real Green Page",
  },
  // {
  //   tabId: "6",
  //   tabName: "Bot Questions",
  //   content: "Bot Questions Page"
  // },
  // {
  //   tabId: "7",
  //   tabName: "Service Questions",
  //   content: "Service Questions Page"
  // },
  // {
  //   tabId: "8",
  //   tabName: "Objections",
  //   content: "Objections Page"
  // }
  // {
  //   tabId: "9",
  //   tabName: "Offers",
  //   content: "Offers Page"
  // }
];

const sansRealGreenTab = [
  // {
  //   tabId: "1",
  //   tabName: "Company Info",
  //   content: "Content company"
  // },

  {
    tabId: "2",
    tabName: "Pricing",
    content: "Content Pricing",
  },

  {
    tabId: "3",
    tabName: "Branding",
    content: "Content branding",
  },

  {
    tabId: "4",
    tabName: "Programs",
    content: "Programs Page",
  },
  // {
  //   tabId: "5",
  //   tabName: "Bot Questions",
  //   content: "Bot Questions Page"
  // },
  // {
  //   tabId: "6",
  //   tabName: "Service Questions",
  //   content: "Service Questions Page"
  // },
  // {
  //   tabId: "7",
  //   tabName: "Objections",
  //   content: "Objections Page"
  // }
  // {
  //   tabId: "8",
  //   tabName: "Offers",
  //   content: "Offers Page"
  // }
];

export const SetupScreen = () => {
  const { path } = useRouteMatch();
  const [renderHelpButtons, setRenderHelpButtons] = useState(false);
  const [company, setCompany] = useState({});
  const [activeCompanyId, setActiveCompanyId] = useState({});
  const [versionControlData, setVersionControlData] = useState({
    open: false,
    type: null,
    id: null,
    botQuestionType: null,
  });
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const [allQuestions, setAllQuestions] = useState([
    {
      topics: ["programName", "description"],
      questionsArray: [
        {
          id: 1,
          question: "What is your first question?",
          answer: "This is your first answer!",
          title: "This is your title",
        },
        {
          id: 2,
          question: "What is your second question?",
          answer: "This is your second answer!",
          title: "This is your title",
        },
      ],
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    const fn = async () => {
      setLoading(true);
      const company = await API.get("dashboardapi", "/v1/company", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
      });

      company.crmSolution === "real-green"
        ? setTabs(realGreenTab)
        : setTabs(sansRealGreenTab);

      if (company.crmSolution !== "real-green") {
        setActiveTab("1");
      }

      setLoading(false);
      setCompany(company);
    };
    if (!tabs.length) {
      fn();
    } else {
      const initialTab = window.location.pathname.split("/")[2];
      if (initialTab) {
        const tabName = initialTab.replace("%20", " ");
        const tabInfo = tabs.find((tab) => tab.tabName === tabName);
        setActiveTab(tabInfo?.tabId ?? "1");
      }
    }
  }, [setTabs, tabs, activeCompanySnapshot?.companyId]);

  const load = async () => {
    setLoading(true);
    const company = await API.get("dashboardapi", "/v1/company", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId,
      },
    });

    activeCompanyState.companyId = company.id;
    company.crmSolution === "real-green"
      ? setTabs(realGreenTab)
      : setTabs(sansRealGreenTab);

    if (company.crmSolution !== "real-green") {
      setActiveTab("1");
    }

    setLoading(false);
    setCompany(company);
  };

  useEffect(() => {
    load();
  }, [activeCompanySnapshot?.companyId]);

  const questionHandler = (val) => {
    const temp = allQuestions.filter(
      (question) => question.topics.filter((a) => a === val).length,
    );
    let allTemp = [];
    if (temp.length) {
      temp.forEach((a) => {
        allTemp = [...allTemp, ...a.questionsArray];
      });
      setData(temp[0].questionsArray);
    }
  };

  const [activeTab, setActiveTab] = useState("1");
  const openVersionControl = (type, id, botQuestionType, customFields) => {
    setVersionControlData({
      open: true,
      type,
      id,
      botQuestionType,
      customFields,
    });
  };

  const obj = {
    0: (
      <PricingTab
        openVersionControl={openVersionControl}
        renderHelpButtons={setRenderHelpButtons}
      />
    ),
    1: (
      <BrandingInfo
        openVersionControl={openVersionControl}
        renderHelpButtons={setRenderHelpButtons}
      />
    ),
    2: (
      <RenderPage
        renderHelpButtons={setRenderHelpButtons}
        key="ddd"
        openVersionControl={openVersionControl}
        questionHandler={questionHandler}
      />
    ),
    ...(company && company.crmSolution === "real-green"
      ? {
          3: <RealGreenCRM renderHelpButtons={setRenderHelpButtons} />,
          4: (
            <BotQuestionsDisplay
              openVersionControl={openVersionControl}
              type={"preModal"}
            />
          ),
          5: (
            <BotQuestionsDisplay
              openVersionControl={openVersionControl}
              type={"serviceQuestions"}
            />
          ),
          6: (
            <BotQuestionsDisplay
              openVersionControl={openVersionControl}
              type={"objections"}
            />
          ),
        }
      : {
          3: (
            <BotQuestionsDisplay
              openVersionControl={openVersionControl}
              type={"preModal"}
            />
          ),
          4: (
            <BotQuestionsDisplay
              openVersionControl={openVersionControl}
              type={"serviceQuestions"}
            />
          ),
          5: (
            <BotQuestionsDisplay
              openVersionControl={openVersionControl}
              type={"objections"}
            />
          ),
        }),
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const closeVersionControl = () => {
    setVersionControlData({
      open: false,
      id: null,
      type: null,
      customFields: null,
      botQuestionType: null,
    });
  };

  const [active, setActive] = useState(false);
  return loading ? (
    <div id="crm-spinner">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  ) : (
    <Router>
      <div>
        <Tab.Container>
          <Nav variant="tabs">
            {tabs.map((t, i) => (
              <LinkContainer
                key={i}
                className={activeTab === t.tabId ? "active_tab" : "non_active"}
                to={`${path}/${t.tabName}`}
              >
                <Nav.Item
                  className="tabs"
                  onClick={() => {
                    closeVersionControl();
                    toggle(t.tabId);
                    setActive(!active);
                  }}
                >
                  {t.tabName}
                </Nav.Item>
              </LinkContainer>
            ))}
          </Nav>
          <Tab.Content>
            {tabs.map((tab, i) => {
              const active = activeTab === tab.tabId;
              return (
                <>
                  <Tab.Pane key={i} active={active}>
                    <div className="tab-content-container">
                      <Switch>
                        <Route
                          exact
                          render={() => {
                            return versionControlData.open ? (
                              <VersionControl
                                open={versionControlData.open}
                                id={versionControlData.id}
                                type={versionControlData.type}
                                botQuestionType={
                                  versionControlData.botQuestionType
                                }
                                customFields={versionControlData.customFields}
                                toggle={() => closeVersionControl()}
                              />
                            ) : (
                              obj[i]
                            );
                          }}
                          path={`${path}/${tab.tabName}`}
                        />
                      </Switch>
                    </div>
                  </Tab.Pane>
                </>
              );
            })}
          </Tab.Content>
        </Tab.Container>
      </div>
    </Router>
  );
};
