import React, { useEffect, useState, useCallback, useContext } from "react";
import { Button, Modal, Dropdown, Spinner } from "react-bootstrap";
import StyleContext from "../../styling/styleContext";
import { API } from "aws-amplify";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const PublishModal = ({ show, hide, onConfirm, onCancel }) => {
  const style = useContext(StyleContext);
  const [loading, setLoading] = useState(false);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  async function publishBotCb() {
    try {
      setLoading(true);
      const result = await API.post("dashboardapi", "/publish-bot", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId,
        },
        body: {},
      });
      if (!result.error) onConfirm();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Generate And Publish Bot(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <p>
            After making changes to a bot, you must publish the bot(s) to make
            the changes available on the live bot(s).
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel} variant="secondary">
          Close
        </Button>
        <Button disabled={loading} onClick={publishBotCb} variant="primary">
          Generate and Publish
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishModal;
