import React, { createRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";

import { debounce } from "lodash";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";

export const SimpleAddressMap = withScriptjs(
  withGoogleMap((props) => {
    const ref = createRef();

    const [markers, setMarkers] = useState([]);
    const [firstLoad, setFirstLoad] = useState(false);
    const [mapKey, setMapKey] = useState(new Date().getTime());

    useEffect(() => {
      if (props.markers.length) {
        setMarkers(props.markers);
        setMapKey(new Date().getTime());
      }
    }, [props.markers]);

    const getMapBounds = () => {
      if (ref.current) {
        const bounds = ref.current.getBounds();
        const zoom = ref.current.getZoom();
        const center = ref.current.getCenter();
        const corners = {
          ne: {
            lat: bounds ? bounds.getNorthEast().lat() : 0,
            lng: bounds ? bounds.getNorthEast().lng() : 0,
          },
          sw: {
            lat: bounds ? bounds.getSouthWest().lat() : 0,
            lng: bounds ? bounds.getSouthWest().lng() : 0,
          },
          zoom,
          center,
        };

        return corners;
      } else {
        return null;
      }
    };

    const mapMoveCb = () => {
      const bounds = getMapBounds();
      if (props.zoomCb && !firstLoad) {
        props.zoomCb(bounds);
      } else {
        setFirstLoad(true);
      }
    };

    const throttledMove = debounce(mapMoveCb, 300);

    return (
      <GoogleMap
        ref={ref}
        key={mapKey}
        defaultZoom={props.zoom || 8}
        defaultCenter={props.center}
        mapTypeId={"satellite"}
        onBoundsChanged={throttledMove}
      >
        {markers.length
          ? markers.map((m) => {
              const key = "map_marker+" + Math.round(Math.random() * 100000);

              let color = "FFC380";
              let label = "L";

              const quote = null;

              if (m.convertedSale) {
                color = m.convertedSale ? "23D4AE" : "FCDC69";
                label = m.convertedSale ? "$" : "L";
              } else {
                if (m.convertedSale) {
                  color = "23D4AE";
                  label = "$";
                }
              }
              /// https://chart.googleapis.com/chart?chst=d_map_spin&chld=0.5|0|34B459|16|b|S

              return (
                <>
                  <Marker
                    key={key}
                    position={m.coords}
                    onClick={
                      props.clickCb
                        ? () => {
                            props.clickCb(quote);
                          }
                        : () => {}
                    }
                    icon={`https://chart.googleapis.com/chart?chst=d_map_spin&chld=1.1|0|${color}|20|b|${label}`}
                  />
                  {m.highlighted ? (
                    <InfoBox
                      defaultPosition={
                        new window.google.maps.LatLng(
                          m.coords.lat,
                          m.coords.lng,
                        )
                      }
                      options={{
                        closeBoxURL: ``,
                        enableEventPropagation: false,
                      }}
                    >
                      <div
                        className="card bg-white"
                        style={{ borderRadius: 15, width: 200 }}
                      >
                        <div className="card-block">
                          <h5 style={{ fontSize: 14 }} className="card-title">
                            {m.customer.name}{" "}
                          </h5>
                          <p className="card-text">
                            This is a wider card with supporting text below as a
                            natural lead-in to additional content. This content
                            is a little bit longer.
                          </p>
                        </div>
                      </div>
                    </InfoBox>
                  ) : null}
                </>
              );
            })
          : null}
      </GoogleMap>
    );
  }),
);

SimpleAddressMap.propTypes = {
  center: PropTypes.object.isRequired,
  markers: PropTypes.array,
  zoom: PropTypes.number,
  clickCb: PropTypes.func,
  zoomCb: PropTypes.func,
};
