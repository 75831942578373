import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import Lightbox from "react-image-lightbox";

export const MesureitImageWidget = (props) => {
  const quote = props.quote;

  const [isOpen, setIsOpen] = useState(false);
  TimeAgo.addLocale(en);

  const timeAgo = new TimeAgo("en-US");
  return quote ? (
    <>
      {quote && quote.estimate && quote.estimate.measurItImage && isOpen ? (
        <Lightbox
          mainSrc={quote.estimate.measurItImage}
          onCloseRequest={() => {
            setIsOpen(false);
          }}
          imageTitle={quote.address.address}
        />
      ) : null}
      <Button onClick={() => setIsOpen(true)} variant="link">
        <div
          className="card card-inverse bg-white"
          style={{
            width: "100%",
            height:
              quote && quote.estimate && quote.estimate.measurItImage
                ? 500
                : 65,
            display: "inline-block",
            background: `url(${
              quote.estimate ? quote.estimate.measurItImage : ""
            })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* <img className="card-img img-responsive" src={quote.estimate.measurItImage} alt="Card image" /> */}
          <div
            className=""
            style={{
              width: "100%",
              height: 90,
              backgroundColor: "#f0f8ffee",
              padding: 10,
            }}
          >
            <h4 className="" style={{ color: "black" }}>
              {quote.address.address}
            </h4>
            <p className="">
              {quote.estimate ? (
                <>
                  Estimate:{" "}
                  {Math.round(quote.estimate.estimate).toLocaleString()}{" "}
                  {quote.estimate.units}
                  <br />
                </>
              ) : null}
              <small className="text-muted-disabled">
                Quoted {timeAgo.format(new Date(quote.createdAt))}
              </small>
            </p>
          </div>
        </div>
      </Button>
    </>
  ) : null;
};

MesureitImageWidget.propTypes = {
  quote: PropTypes.object.isRequired,
};
