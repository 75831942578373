import React, { Fragment, useState, useContext, useCallback } from "react";
import { Form, Button, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import styleContext from "../../styling/styleContext";
import { API } from "aws-amplify";
import DiscountForm from "./discounts/discount-form";
import useForm from "./useForm";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const EditProgram = ({
  programs,
  program,
  active,
  setActive,
  onSaveComplete,
}) => {
  const [validated, setValidated] = useState(false);

  const style = useContext(styleContext);
  const [discountTables, setDiscountTables] = useState({});
  const [programTypes, setProgramTypes] = useState([]);
  const notify = (val) => toast(val);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const formSubmit = useCallback(
    async (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      const formData = new FormData(event.target),
        formDataObj = Object.fromEntries(formData.entries());

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        return;
      }

      try {
        await API.post("dashboardapi", "/v1/programs", {
          headers: {
            "Company-Id": activeCompanySnapshot?.companyId,
          },
          body: {
            id: program.id == "newId" ? null : program.id,
            ...formDataObj,
            useRealGreenPricingEngine:
              formDataObj.useRealGreenPricingEngine || false,
            realGreenApplicationDiscountCode:
              formDataObj.useRealGreenPricingEngine
                ? JSON.stringify(discountTables)
                : null,
            realGreenPrepayDiscountCode: formDataObj.useRealGreenPricingEngine
              ? formDataObj.realGreenPrepayDiscountCode
              : null,
          },
        });
        onSaveComplete();
      } catch (err) {
        console.log("err: ", err);
        notify("Failed to Modify Program");
      }
    },
    [setValidated, program, discountTables, activeCompanySnapshot?.companyId],
  );

  useEffect(() => {
    getProgramTypes();
    const discounts =
      !program.realGreenDiscountTable &&
      !program.realGreenApplicationDiscountCode
        ? []
        : JSON.parse(program.realGreenApplicationDiscountCode);
    setDiscountTables(discounts);
  }, [program]);

  const getProgramTypes = async () => {
    const data = await API.get("dashboardapi", "/v1/service-types", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId,
      },
    });
    const mappedData = data?.message
      ?.map((item) => {
        return { label: item.name, value: item.name };
      })
      ?.sort((a, b) => {
        if (a.label == b.label) {
          return 0;
        }

        return a.label < b.label ? -1 : 1;
      });
    mappedData.unshift({ label: "", value: "" });
    setProgramTypes(mappedData);
  };

  const modifyHeaders = {
    ...style.styleSizing,
    color: style.colors.blue,
    paddingLeft: 5,
  };

  const modifyInputs = {
    ...style.styleSizing,
    color: style.colors.green,
    fontSize: 14,
  };

  return (
    <Form
      noValidate
      validated={validated}
      key="edit-program"
      style={{ maxWidth: "1140px" }}
      onSubmit={formSubmit}
    >
      <br />
      <hr />
      <Fragment key={`edit-program-1`}>
        <h2
          style={{
            ...style.styleSizing,
            fontSize: 24,
            fontWeight: 500,
            marginBottom: 15,
          }}
          id="real-green-header"
        >
          Modify Program
        </h2>

        <div
          style={{
            background: "whitesmoke",
            marginLeft: " 0px",
            borderRadius: "25px",
            padding: "50px",
          }}
          className="container"
        >
          <div key={`programs_form_1`}>
            <Form.Group check inline>
              <Form.Label
                style={{ ...style.styleSizing, color: style.colors.blue }}
                check
              >
                <Form.Check.Input
                  style={{ marginLeft: "4px", marginTop: "7px" }}
                  name="active"
                  type="checkbox"
                  value={true}
                  defaultChecked={program.active}
                />
                <div style={{ paddingLeft: "25px" }}>Program Active</div>
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="formProgramName">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="program-name"
              >
                Program Name
              </Form.Label>
              <Form.Control
                required
                style={{
                  ...modifyInputs,
                  boxShadow: "0 0 10px rgba(0,0,0,0.15)",
                }}
                type="text"
                name="programName"
                defaultValue={program.programName}
              />
              <Form.Control.Feedback type="invalid">
                Program Name is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formProgramDescription">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="program-description"
              >
                Program Description
              </Form.Label>
              <Form.Control
                required
                style={{
                  ...modifyInputs,
                  boxShadow: "0 0 10px rgba(0,0,0,0.15)",
                }}
                type="text"
                name="programDescription"
                defaultValue={program.programDescription}
                as="textarea"
                rows={5}
              />
              <Form.Control.Feedback type="invalid">
                Program Description is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formStartMonth">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="start-month"
              >
                Start Month
              </Form.Label>
              <Form.Label>
                <Form.Control
                  as="select"
                  style={{
                    ...modifyInputs,
                    margin: "10px",
                    padding: "5px",
                    backgroundPosition: "calc(100% - 20px) center",
                    minWidth: "130px",
                  }}
                  defaultValue={program?.programStartMonth || "1"}
                  name="programStartMonth"
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Form.Control>
              </Form.Label>
              {/* <Form.Control style={{ boxShadow: '0 0 10px rgba(0,0,0,0.15)'}} onChange={handleChange} name='programEndMonth' type="number" value={values.programEndMonth || a.programEndMonth} /> */}
            </Form.Group>
            <Form.Group controlId="formEndMonth">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="end-month"
              >
                End Month
              </Form.Label>
              <Form.Label>
                <Form.Control
                  as="select"
                  style={{
                    ...modifyInputs,
                    margin: "10px",
                    padding: "5px",
                    backgroundPosition: "calc(100% - 20px) center",
                    minWidth: "130px",
                  }}
                  defaultValue={program.programEndMonth || "12"}
                  name="programEndMonth"
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April </option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </Form.Control>
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="programType">
              <Form.Label
                style={{ ...modifyHeaders }}
                className="context edit-label"
                id="program-type"
              >
                Program Type
              </Form.Label>

              {programTypes && programTypes.length && (
                <Form.Control
                  required
                  as="select"
                  style={{
                    ...modifyInputs,
                    margin: "10px",
                    padding: "5px",
                    backgroundPosition: "calc(100% - 20px) center",
                  }}
                  defaultValue={program.programType}
                  name="programType"
                >
                  {programTypes.map((types, typeI) => (
                    <option key={`${types.label}-${typeI}`} value={types.label}>
                      {types.label}
                    </option>
                  ))}
                </Form.Control>
              )}
              <Form.Control.Feedback
                type="invalid"
                style={{ paddingLeft: "8px", marginTop: "-4px" }}
              >
                Program Type is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <div>
                <DiscountForm
                  program={program}
                  rounds={discountTables}
                  setRounds={(newRounds) => {
                    setDiscountTables(newRounds);
                  }}
                />
              </div>
            </Form.Group>

            <Button
              type="submit"
              style={{
                background: "#009879",
                ...style.submitButton(style.colors.green),
              }}
              variant="primary"
            >
              Submit
            </Button>
            <Button
              style={{
                margin: "10px",
                ...style.submitButton("#DD6777"),
                borderColor: "#DD6777",
              }}
              onClick={() => setActive(false)}
              variant="danger"
              type="submit"
            >
              Close
            </Button>
          </div>
          <div></div>
        </div>
      </Fragment>
      <br />
      <hr />
      <ToastContainer />
    </Form>
  );
};

export default EditProgram;
