import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import StyleContext from "../../../styling/styleContext";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Spinner,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";

function CustomToggle({ children, eventKey, toggled, setToggled }) {
  const style = useContext(StyleContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    // console.log("totally custom!")
    setToggled(!toggled),
  );

  return (
    <Button
      type="button"
      style={{
        ...style.styleSizing,
        backgroundColor: style.colors.green,
        fontSize: 16,
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}

function AccordionComp({ images, toggled, setToggled }) {
  const style = useContext(StyleContext);
  const defaultActiveKey = toggled ? "0" : undefined;
  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      <Card
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Card.Header>
          <CustomToggle
            style={{ ...style.styleSizing }}
            toggled={toggled}
            setToggled={setToggled}
            eventKey="0"
          >
            {toggled ? "Close Images" : "Open Images"}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Row style={{ width: "60%", background: style.colors.green }}>
            {images.map(({ id, title, src }, index) => {
              return (
                <Col key={`Images_Key_${index}`} xs="12" lg="6" md="6">
                  <Card style={{ width: "auto", height: "150px" }}>
                    <Card.Header
                      style={{
                        backgroundColor: style.colors.green,
                        color: "white",
                        fontFamily: style.styleSizing.fontFamily,
                      }}
                    >
                      {`Option ${index + 1} - ${title}`}
                    </Card.Header>
                    <Card.Body>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        alt={title}
                        src={src}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

const Images = ({ data: testImage, openModal, toggled, setToggled }) => {
  const style = useContext(StyleContext);

  const images =
    (testImage &&
      testImage
        .filter(
          ({ options }) => options && options.length > 0 && options[0].image,
        )
        .reduce((images, { id, options }) => {
          images.push({
            id,
            images: options.map(({ image: src, value: title }) => ({
              title,
              src,
            })),
          });
          return images;
        }, [])) ||
    [];

  return images.map(({ id, images }) => (
    <div key={`Images_Card_${id}`}>
      <h2
        style={{
          ...style.styleSizing,
          color: style.colors.blue,
          fontSize: 24,
          marginBottom: 10,
          textAlign: "left",
          fontWeight: 500,
        }}
      >
        Image Options
      </h2>

      <div style={{ width: "70%" }}>
        <Card className="draggable-ids">
          <Card.Header
            style={{
              backgroundColor: style.colors.green,
              color: "white",
              fontWeight: 400,
              textAlign: "left",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  ...style.styleSizing,
                }}
                className="order"
              >
                <i className="fa fa-sort mr-3"></i>

                {id}
              </div>

              <i
                style={{ cursor: "pointer" }}
                onClick={() => openModal(images)}
                className="icon-pencil"
              ></i>
            </div>
          </Card.Header>

          <Card.Body>
            {images && (
              <AccordionComp
                images={images}
                toggled={toggled}
                setToggled={setToggled}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  ));
};

export default Images;
